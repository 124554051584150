// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-municipal-transcription-js": () => import("./../../../src/pages/municipal-transcription.js" /* webpackChunkName: "component---src-pages-municipal-transcription-js" */),
  "component---src-pages-success-discount-js": () => import("./../../../src/pages/success-discount.js" /* webpackChunkName: "component---src-pages-success-discount-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-upload-discount-js": () => import("./../../../src/pages/upload-discount.js" /* webpackChunkName: "component---src-pages-upload-discount-js" */),
  "component---src-pages-upload-js": () => import("./../../../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */)
}


import useDarkMode from 'hooks/useDarkMode'
import React, { createContext } from 'react'

export const ThemeContext = createContext('light')

const ThemeProvider = ({ children }) => {
  const [theme, toggleTheme] = useDarkMode()

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
